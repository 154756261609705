import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import {
    IoLogoFacebook,
    IoLogoTwitter,
    IoLogoLinkedin,
    IoIosCalendar,
} from "react-icons/io";

import { Wrapper, Row, Box } from "../util";
import {
    CallToAction,
    AuthorCard,
    Breadcrumb,
    Sidebar,
} from "../site";

import Layout from "../layout";
import Seo from "../seo";


const SocialShare = styled.div`
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    font-size: 25px;
  }

  > * + * {
    margin-left: 15px;
  }
`;

const PostContainer = styled.article`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;

  .published-date {
    color: #777;
  }
`;

const PostImage = styled.div`
  max-width: 600px;

  > .gatsby-image-wrapper {
    max-width: 100%;

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      height: 205px !important;
    }
  }
`;

const PostContent = styled.div`
  padding-bottom: 30px;

  img {
    display: inline-block;
  }
`;

const CardRow = styled(Row)`
  width: calc(100% + 30px);
  align-items: stretch;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

// Hangover code for images with links
// ... on ContentfulImageWithLink {
//   __typename
//   contentful_id
//   image {
//     gatsbyImageData(layout: FULL_WIDTH, quality: 100)
//   }
//   link
// }

export const BlogPost = (props) => {
    const website_url = process.env.GATSBY_SITE_URL;

    const blog = props.blog;
    const options = props.contentOptions;

    let formattedDate, resourceImage, resourceImageURL, content;

    if (props.isTemplate) { //as opposed to preview page
        formattedDate = blog.publishedDate;

        resourceImage = (
            <GatsbyImage
                className="-addShadow"
                image={blog.image.gatsbyImageData}
                alt={`${blog.title} | Droppah`}
                title={`${blog.title} | Droppah`}
            />
        );
        resourceImageURL = blog.image ? "https:" + blog.image.file.url : "";

        content = (renderRichText(blog.content, options));

    } else {
        const dateFormat = new Intl.DateTimeFormat('en-US', {
            year: "numeric",
            month: "long",
            day: "numeric",
            timeZone: 'UTC'
        });
        const publishedDate = new Date(blog.publishedDate);

        if (publishedDate instanceof Date && !isNaN(publishedDate)) {
            formattedDate = dateFormat.format(publishedDate);
        }

        resourceImage = (
            blog.image && <img
                className="-addShadow"
                src={blog.image.fields.file.url}
                alt="Preview Resource"
            />
        );
        resourceImageURL = blog.image ? "https:" + blog.image.fields.file.url : "";

        content = documentToReactComponents(blog.content, options);
    }

    // const handleTagClick = (tag) => {
    //     if (typeof window !== "undefined") {
    //         window.sessionStorage.setItem("blog_search", tag);
    //     }
    // };

    const handleTagClick = (e) => {
        window.sessionStorage.setItem("blog_search", e.target.innerText);
    };

    const handleShare = function (e) {
        if (typeof window !== "undefined") {
            if (e.target.name === "facebook") {
                window.open(
                    "https://www.facebook.com/sharer/sharer.php?u=" +
                    website_url +
                    "/blog/" +
                    blog.url,
                    "_blank",
                    "width=555,height=760"
                );
            }

            if (e.target.name === "twitter") {
                window.open(
                    "https://twitter.com/intent/tweet?text=" +
                    encodeURIComponent(blog.title) +
                    "&url=" +
                    website_url +
                    "/blog/" +
                    blog.url,
                    "_blank",
                    "width=732,height=800"
                );
            }

            if (e.target.name === "linkedin") {
                window.open(
                    "https://www.linkedin.com/shareArticle?mini=true&url=" +
                    website_url +
                    "/blog/" +
                    blog.url +
                    "&title=" +
                    encodeURIComponent(blog.title),
                    "_blank",
                    "width=555,height=555"
                );
            }
        }
    };

    return (
        <Layout>
            <Seo
                title={blog.title + " | Droppah"}
                description={blog.description}
                image={resourceImageURL}
                pathname={props.location.pathname}
                author={blog.author ? blog.author : ""}
                type="article"
            />
            <Helmet>
                {blog.image && (
                    <script type="application/ld+json">
                        {`
            {
              "@context": "http://schema.org",
              "@type": "BlogPosting",
              "url": ` +
                            JSON.stringify(
                                process.env.GATSBY_SITE_URL + "/blog/" + blog.url
                            ) +
                            `,
              "headline": ` +
                            JSON.stringify(blog.title) +
                            `,
              "image": ` +
                            JSON.stringify("https:" + blog.image.gatsbyImageData) +
                            `,
              "dateCreated": ` +
                            JSON.stringify(blog.publishedDate) +
                            `,
              "datePublished": ` +
                            JSON.stringify(blog.publishedDate) +
                            `,
              "dateModified": ` +
                            JSON.stringify(blog.publishedDate) +
                            `,
              "accountablePerson": {
                "@type": "Person",
                "name": ` +
                            JSON.stringify(blog.author) +
                            `
              },
              "author": {
                "@type": "Person",
                "name": ` +
                            JSON.stringify(blog.author) +
                            `
              },
              "creator": {
                "@type": "Person",
                "name": ` +
                            JSON.stringify(blog.author) +
                            `
              },
               "publisher": {
                "@type": "Organization",
                "name": "Droppah",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${process.env.GATSBY_SITE_URL +
                            `/metaImages/Droppah_SchemaLogo.png`}"
                }
              },
              "description": ` +
                            JSON.stringify(blog.description) +
                            `,
              "mainEntityOfPage": "${process.env.GATSBY_SITE_URL + `/blog`}"
            }
          `}
                    </script>
                )}
            </Helmet>
            <Breadcrumb
                parentPage="Resources"
                parentPageLink="/blog"
                currentPage={blog.title}
            />
            <PostContainer>
                <Wrapper className="-center" stackGap={70}>
                    <Row stackGap={40}>
                        <Box size={75} stackGap={60}>
                            <Box className="-textCenter" stackGap={10}>
                                <h6>
                                    <div
                                        className={
                                            `badge -sm ` +
                                            (blog.topic
                                                ? "-" + blog.topic.replace(/[^A-Z0-9]/gi, "")
                                                : "")
                                        }
                                    >
                                        {blog.topic}
                                    </div>
                                </h6>
                                <h1>{blog.title}</h1>
                                <h6 className="published-date">
                                    <IoIosCalendar css={{ margin: "0px 7px -2px 0" }} />
                                    {formattedDate}
                                </h6>
                            </Box>
                            <Box stackGap={35}>
                                {blog.image && (
                                    <PostImage className="-center">
                                        {resourceImage}
                                    </PostImage>
                                )}
                                <Row alignCenter stackGap={30}>
                                    <AuthorCard author={blog.author} />
                                    {/* <ReadTime>
                                                <IoIosTime /> {read_time.toFixed(0)}&nbsp;
                                                <span>minute read</span>
                                            </ReadTime> */}
                                    <SocialShare>
                                        <a
                                            href="#!"
                                            name="facebook"
                                            data-tooltip="Share on Facebook"
                                            onClick={handleShare}
                                        >
                                            <IoLogoFacebook css={{ pointerEvents: "none" }} />
                                        </a>
                                        <a
                                            href="#!"
                                            name="twitter"
                                            data-tooltip="Share on Twitter"
                                            onClick={handleShare}
                                        >
                                            <IoLogoTwitter css={{ pointerEvents: "none" }} />
                                        </a>
                                        <a
                                            href="#!"
                                            name="linkedin"
                                            data-tooltip="Share on LinkedIn"
                                            onClick={handleShare}
                                        >
                                            <IoLogoLinkedin css={{ pointerEvents: "none" }} />
                                        </a>
                                    </SocialShare>
                                </Row>
                                <hr />
                            </Box>
                            <Box stackGap={50}>
                                <PostContent className="font-bump">
                                    {content}
                                </PostContent>
                                {blog.tags && (
                                    <Box>
                                        {blog.tags.map((i) => {
                                            return (
                                                <Link
                                                    to="/blog"
                                                    css={{
                                                        display: "inline-block",
                                                        marginRight: "10px",
                                                        marginBottom: "10px",
                                                    }}
                                                    key={i}
                                                    onClick={handleTagClick}
                                                >
                                                    <span className="badge -grey" key={i}>
                                                        {i}
                                                    </span>
                                                </Link>
                                            );
                                        })}
                                    </Box>
                                )}
                                <Row stackGap={60} alignCenter>
                                    <AuthorCard author={blog.author} />
                                    <SocialShare>
                                        <a
                                            href="#!"
                                            name="facebook"
                                            data-tooltip="Share on Facebook"
                                            onClick={handleShare}
                                        >
                                            <IoLogoFacebook css={{ pointerEvents: "none" }} />
                                        </a>
                                        <a
                                            href="#!"
                                            name="twitter"
                                            data-tooltip="Share on Twitter"
                                            onClick={handleShare}
                                        >
                                            <IoLogoTwitter css={{ pointerEvents: "none" }} />
                                        </a>
                                        <a
                                            href="#!"
                                            name="linkedin"
                                            data-tooltip="Share on LinkedIn"
                                            onClick={handleShare}
                                        >
                                            <IoLogoLinkedin css={{ pointerEvents: "none" }} />
                                        </a>
                                    </SocialShare>
                                </Row>
                            </Box>
                        </Box>
                        <Box size={25} css={{ position: "sticky", top: "80px" }}>
                            <Sidebar />
                        </Box>
                    </Row>
                    {/* {blog.relatedArticles && (
                            <>
                                <hr />
                                <Box stackGap={50}>
                                <Row alignCenter stackGap={30} centerOnMobile>
                                    <h4 className="-fontBold h2">Keep reading</h4>
                                    <Link className="link-floating" to="/blog">
                                    All Resources
                                    </Link>
                                </Row>
                                <CardRow isWrap justify="flex-start">
                                    {blog.relatedArticles.map((edge, i) => {
                                    return (
                                        <ResourceCard
                                        isFeatured={false}
                                        key={i}
                                        image={edge.image && edge.image.gatsbyImageData}
                                        topic={edge.resourceTopic}
                                        title={edge.title}
                                        url={edge.url}
                                        show={true}
                                        />
                                    );
                                    })}
                                </CardRow>
                                </Box>
                            </>
                            )} */}
                </Wrapper>
            </PostContainer>
            <CallToAction />
        </Layout>
    );
};